import React, { useState, useEffect } from "react";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Authorization } from '../Auth/Authorization'
import { history } from '../Auth/index';

export default function NavBar(props) {
    const [showLogin, setShowLogin] = useState(false)
    const [User, setUser] = useState('')
    const [admin, setAdmin] = useState(false)
    useEffect(() => {
      if (props.currentUser == null){
        setShowLogin(showLogin => true);
      }else{
        setShowLogin(showLogin => false);
        setUser(props.currentUser)
      }
      if (JSON.parse(localStorage.getItem('role')) === 'admin'){
        setAdmin(true)
      }
    }, [props.currentUser])
        
    const handleSelect = eventKey => {
        if (eventKey == 3){
            Authorization.logout()
            history.push('/login')
            document.location.href = '/login'
        }
    }

        return (
            <div id="NavBar" className="mb-3">
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand eventKey={1} href="/">Membership Admin Tool for My TVB</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            { showLogin ? null : admin ?
                                <Nav.Link href="/add_user">Add new user</Nav.Link>
                                : null
                            }
                        </Nav>
                        { showLogin ? null :
                            <Navbar.Text>Logged in as {User}</Navbar.Text>
                        }
                        <Nav onSelect={handleSelect} className="justify-content-end">
                        { showLogin ? 
                            <Nav.Link eventKey={2} href="/login">
                            Login
                            </Nav.Link>
                            :    
                            <Nav.Link eventKey={3}>
                            Logout
                            </Nav.Link>
                        }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    
}
