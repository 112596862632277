import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Authorization } from './components/Auth/Authorization';
import { authHeader } from './components/Auth/index';

import Detail from "./components/Detail/Detail";
import NavBar from "./components/NavBar/NavBar";
import Account_Table from "./pages/account_table"
import Login from "./pages/Login"
import Add_user from "./pages/add_user"

function App() {

  const [currentUser, setCurrentUser] = useState(null)

  useEffect( () => {
    Authorization.currentUser.subscribe(x => setCurrentUser(x));
  
    return () => {
     Authorization.currentUser.subscribe(x => setCurrentUser(x));
      
      authHeader()
    }
  
  }, [])

  const renderRedirect = () => {
      if(JSON.parse(localStorage.getItem('currentUser')) == null){
        return <Redirect to='/login' />
      }
  }

  return (
    <div className="App">
        <NavBar currentUser={currentUser} />
        <Router>
        {renderRedirect()}
          <Switch>
            <Route exact path="/" component={Account_Table} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/add_user" component={Add_user} />
            <Route exact path="/detail/:tvb_id" component={Detail} />
          </Switch>
        </Router>
      
    </div>
  );
}

export default App;
