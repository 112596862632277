import axios from 'axios';

export default function authHeader() {
    // return authorization header with jwt token
    axios.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        config.headers.common['Authorization'] = 'Bearer ' + token;
        config.headers.common['Content-Type'] = 'application/json';
        return config;
    })

}