import { BehaviorSubject } from 'rxjs';
import { Login, Signup } from '../../api/api'

import { handleResponse } from './index';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const Authorization = {
    login,
    logout,
    checkToken,
    add_user,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    const data = {
        username: username,
        password: password
    };

    return Login(data)
        .then(handleResponse)
        .then(function (res) {
            if(res.code === 200){
                localStorage.setItem('currentUser', JSON.stringify(res.username));
                localStorage.setItem('token', JSON.stringify(res.token))
                localStorage.setItem('role', JSON.stringify(res.role))
                currentUserSubject.next(res.username);
            }
            return res;
        })
}

function add_user(username, password, role) {
    const data = {
        username: username,
        password: password,
        role: role
    };

    return Signup(data)
        .then(handleResponse)
        .then(function (res) {
            return res;
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    currentUserSubject.next(null);
    
}

function checkToken() {
    //return axios.get('http://10.2.8.61:8080');
}