import React, { useState, useCallback } from "react";
import Alert from "react-bootstrap/Alert";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import {Account_search} from '../api/api'
import { Icon } from 'antd';
import 'antd/dist/antd.css';
import { Redirect } from 'react-router'

export default function Detail(props) {

const [staff_number, setStaff_number] = useState('')
const [chinese_name, setChinese_name] = useState('')
const [english_name, setEnglish_name] = useState('')
const [email, setEmail] = useState('')
const [login_mobile_number, setLogin_mobile_number] = useState('')
const [isSending, setIsSending] = useState(false)
const [empty, setEmpty] = useState(false)
const [resData, setResData] = useState([])
const [showDetail, setShowDetail] = useState(false)
const [tvbid, setTvbid] = useState('')

const [staff_number_disable, setStaff_number_disable] = useState(false)
const [chinese_name_disable, setChinese_name_disable] = useState(false)
const [english_name_disable, setEnglish_name_disable] = useState(false)
const [email_disable, setEmail_disable] = useState(false)
const [login_mobile_number_disable, setLogin_mobile_number_disable] = useState(false)

const setDisable = (id) => {
        setStaff_number_disable(true)
        setChinese_name_disable(true)
        setEnglish_name_disable(true)
        setEmail_disable(true)
        setLogin_mobile_number_disable(true)
    if (id === "staff_number"){
        setStaff_number_disable(false)
    }
    if (id === "chinese_name"){
        setChinese_name_disable(false)
    }
    if (id === "english_name" ){
        setEnglish_name_disable(false)
    }
    if (id === "email"){
        setEmail_disable(false)
    }
    if (id === "login_mobile_number"){
        setLogin_mobile_number_disable(false)
    }
}

const Clear = () => {
    setStaff_number('')
    setChinese_name('')
    setEnglish_name('')
    setEmail('')
    setLogin_mobile_number('')
    setStaff_number_disable(false)
    setChinese_name_disable(false)
    setEnglish_name_disable(false)
    setEmail_disable(false)
    setLogin_mobile_number_disable(false)
}
const sendRequest = useCallback(async () => {
    const reqData = {
        chinese_name: chinese_name,
        english_name: english_name,
        email: email,
        login_mobile_number: login_mobile_number,
        staff_number: staff_number
    }
    // don't send again while we are sending
    if (isSending) return
    // update state
    setIsSending(true)
    // send the actual request
    await Account_search(reqData)
    .then(res => {
        console.log(res.data)
        if (res.data.members.length === 0){
            setEmpty(true)
            setResData([])
        }else{
            res.data.members.forEach(element => {
                if (element.tvbid_id != null){
                    element.detail = (<Icon type="search" />)
                }
            });
            setTimeout(10)
            setResData(res.data.members)
            setEmpty(false)
        }
    })
    .catch(err => {
        console.log(err)
    })
    // once the request is sent, update state again
    setIsSending(false)
  }, [isSending, chinese_name, english_name, email, login_mobile_number, staff_number])

const detail = async (rowInfo, columns) => {

    if (columns.id === 'detail' && rowInfo !== undefined && rowInfo.original.tvbid_id !== null){
        await setTvbid(rowInfo.original.tvbid_id)
        setShowDetail(true)   
    }
}

 
  const columns = [
    {
        Header: 'Chinese Name',
        accessor: 'chinese_name'
    },
    {
        Header: 'English Name',
        accessor: 'english_name'
    },
    {
        Header: 'Email',
        accessor: 'email'
    },
    {
        Header: 'Login Mobile Number',
        accessor: 'login_mobile_number'
    },
    {
        Header: 'Staff / Artiste Number',
        accessor: 'staff_number'
    },
    {
        Header: 'Detail',
        accessor: 'detail'
    },]
        
return (
    <div id="Account Search">
        { showDetail  ? <Redirect to={'/detail/'+tvbid} /> :
        <Container>
            <Breadcrumb className="mb-3">
                <Breadcrumb.Item href="#"> Customer – Account Enquiry</Breadcrumb.Item>
            </Breadcrumb>
            <Form className="mb-3">
                <Row className="mb-3">
                    <h2>Account Enquiry</h2>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Control 
                        id='chinese_name'
                        type="text"
                        disabled={chinese_name_disable}
                        value={chinese_name}
                        onChange = { event => {
                            setChinese_name(event.target.value);
                            setDisable(event.target.id)
                          }
                        }
                        margin="normal"
                        variant="outlined"
                        placeholder="Chinese Name" />
                    </Col>
                    <Col>
                        <Form.Control 
                        id='english_name'
                        type="text" 
                        disabled={english_name_disable}
                        value={english_name}
                        onChange= { event => {
                            setEnglish_name(event.target.value);
                            setDisable(event.target.id)
                          }
                        }
                        margin="normal"
                        variant="outlined"
                        placeholder="English Name" />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Control 
                        id='email'
                        type="email" 
                        disabled={email_disable}
                        value={email}
                        onChange={ event => {
                            setEmail(event.target.value);
                            setDisable(event.target.id)
                          }
                        }
                        margin="normal"
                        variant="outlined"
                        placeholder="Email" />
                    </Col>
                    <Col>
                        <Form.Control 
                        id='login_mobile_number'
                        type="text" 
                        disabled={login_mobile_number_disable}
                        value={login_mobile_number}
                        onChange={event => {
                            setLogin_mobile_number(event.target.value);
                            setDisable(event.target.id)
                          }
                        }
                        margin="normal"
                        variant="outlined"
                        placeholder="Login Mobile Number" />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Control 
                        id='staff_number'
                        type="text" 
                        value={staff_number}
                        disabled={staff_number_disable}
                        onChange={event => {
                            setStaff_number(event.target.value);
                            setDisable(event.target.id)
                          }}
                        margin="normal"
                        variant="outlined"
                        placeholder="Staff / Artiste Number" />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col className="float-right">
                        <Button href="#"  disabled={isSending} onClick={Clear}>Clear</Button>
                    </Col>
                    <Col className="float-right">
                        <Button href="#"  disabled={isSending} onClick={sendRequest}>Search</Button>
                    </Col>
                </Row>
                
            </Form>
            { empty ? <Alert variant={'danger'}>
                No match result found
            </Alert> : null
            }
            <Row className="mb-3">
                <Col>
                    <ReactTable 
                        data={resData}
                        columns={columns}
                        defaultPageSize = {5}
                        getTdProps={(state, rowInfo, columns) => {
                            return {
                              onClick: (e, handleOriginal) => {
                                detail(rowInfo, columns)
                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                  handleOriginal()
                                }
                              }
                            }
                          }}
                    />
                </Col>
            </Row>
        </Container>
        }
    </div>
);
    
}
