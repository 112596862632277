import React, { Component } from "react";
import Alert from "react-bootstrap/Alert"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Authorization } from "../components/Auth/Authorization";

class Login extends Component {

    constructor(props) {
        super(props);

        // initialize the states for this components
        this.state = {
            username: "",
            password: "",
            role: "",
            error: false,
            error_message: '',
            success: false
        };
    }

    // validate the form
    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    // set all the changed fields of the form to the states of this component
    handleChange = event => {
        this.setState({[event.target.id]: event.target.value});
    }

    // post the input data to server for checking
    handleSubmit = event => {
        event.preventDefault();

        const username = this.state.username;
        const role = this.state.role;
        const password = this.state.password;

        const self = this;
        Authorization.add_user(username, password, role)
            .then(function (res) {
                if(res.code === 200){
                    self.setState({
                        success: true
                    })
                }else{
                    self.setState({
                        error: true,
                        error_message: res.msg
                    });
                }
            })
            .catch(function (err) {
                console.log(err)
                //const errors = err.response.data.errors;
                
                self.setState({
                    error: true,
                    error_message: err.msg
                });
                
            })
        
    };

    render() {
        return (
            <div id="add user">
                <h1>Add User</h1>
                <Container>
                    <Form autoComplete="off" onSubmit={this.handleSubmit}>
                        <Form.Group >
                            <Form.Label>Username</Form.Label>
                            <Form.Control 
                                autoFocus 
                                id='username'
                                type="username" 
                                placeholder="Enter your username" 
                                value={this.state.username}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                            />
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type="password"
                                name="password"
                                id="password"
                                autoComplete="new-password"
                                placeholder="Enter your password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined" />
                        </Form.Group>
                        <Form.Group controlId="role" >
                            <Form.Label>User Role</Form.Label>
                            <Form.Control as="select" onChange={this.handleChange}>
                                <option value = 'normal_user'>Normal User</option>
                                <option value = 'admin'>Admin</option>
                            </Form.Control>
                        </Form.Group>
                        {
                            this.state.error ? <Alert color='error' variant={'danger'}> {this.state.error_message} </Alert>: null
                        }
                        {
                            this.state.success ? <Alert variant={'success'}> Add user success </Alert> : null
                        }
                            <Button
                                variant="outline-primary"
                                size="medium"
                                block='true'
                                disabled={!this.validateForm()}
                                type="submit"
                            >
                                Add new user
                        </Button>
                    </Form>
                </Container>
            </div>
        );
    }
}

export default Login;
