import React, { useState, useEffect, useImperativeHandle } from "react";
import Button from 'react-bootstrap/Button';
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {Staff_detail, Update_detail} from '../../api/api'
import Alert from "react-bootstrap/Alert";
import './Detail.css'
export default function Detail(props) {

const gender_list = ["","Male","Female", "Other"] 
const [systemID, setSystemID] = useState("N/A")
const [staff_number, setStaff_number] = useState("N/A")
const [account_creation_date, setAccount_creation_date] = useState("N/A")
const [first_name, setFirst_name] = useState("N/A")
const [last_name, setLast_name] = useState("N/A")
const [nickname, setNickname] = useState("N/A")
const [gender, setGender] = useState("N/A")
const [email, setEmail] = useState("N/A")
const [login_mobile_number, setLogin_mobile_number] = useState("N/A")
const [login_mobile_verified_means, setLogin_mobile_verified_means] = useState("N/A")
const [login_phone_code, setLogin_phone_code] = useState("N/A")
const [region, setRegion] = useState("N/A")
const [mobile_numbers, setMoblie_numbers] = useState("N/A")
const [mobile_verified_means, setMoblie_verfied_means] = useState("N/A")
const [phone_code, setPhone_code] = useState("N/A")
const [birth_year, setBirth_year] = useState("N/A")
const [birth_month, setBirth_month] = useState("N/A")
const [pics, setPics] = useState("N/A")
const [pics_date, setPics_date] = useState("N/A")
const [consent, setConset] = useState("N/A")
const [consent_date, setConsent_date] = useState("N/A")
const [edit, setEdit] = useState(false)
const [stop, setStop] = useState(false)
const [error, setError] = useState(false)
const [error_msg, setError_msg] = useState('')
const [success, setSuccess] = useState(false)
const [admin, setAdmin] = useState(false)
useEffect(() => {
    if (JSON.parse(localStorage.getItem('role')) === 'admin'){
        setAdmin(true)
    }
    const reqData = {
        request :  {
            customer: {
                tvbid_id: props.match.params.tvb_id
            }
        }
    }
    if (stop === false){
      setStop(true)
      Staff_detail(reqData)
      .then(res=> {
        if(res.data.err){
            console.log(res.data.err)
            setError(true)
            setError_msg(res.data.err.message)
        }else{
            let tvb_account = res.data.tvb_account.tvb_account
            setStaff_number(tvb_account.staff_number)
            setAccount_creation_date(tvb_account.created_at)
            setLast_name(tvb_account.last_name)
            setFirst_name(tvb_account.first_name)
            setNickname(tvb_account.nickname)
            setGender(gender_list[parseInt(tvb_account.gender,10)])
            setEmail(tvb_account.email)
            setLogin_mobile_number(tvb_account.login_mobile_number)
            setLogin_mobile_verified_means(tvb_account.login_mobile_number_verified_by)
            setLogin_phone_code(tvb_account.login_mobile_phone_code)
            //setRegion(tvb_account.region)
            setPhone_code(tvb_account.mobile_phone_code)
            setMoblie_numbers(tvb_account.mobile_number)
            setMoblie_verfied_means(tvb_account.mobile_number_verified_by)
            setBirth_year(tvb_account.year_of_birth)
            setBirth_month(tvb_account.month_of_birth)
            setPics(tvb_account.acceptance_of_pics)
            setPics_date(tvb_account.pics_accepted_at)
            setConset(tvb_account.acceptance_of_marketing_consent)
            setConsent_date(tvb_account.marketing_consent_accepted_at)
        }
      })
      .catch(err=> {
          console.log(err);
      })
      
    }
  },[])

const handleClick = () => {
    setEdit(!edit)
}
 
const handleSubmit = () => {
    let update_consent
    let update_pics
    if(pics === '1'){
        update_pics = true
    }else{
        update_pics = false
    }
    if(consent === '1'){
        update_consent = true
    }else{
        update_consent = false
    }
    const reqData = {
        request :  {
            customer: {
                tvbid_id: props.match.params.tvb_id,
                staff_number: staff_number,
                acceptance_of_pics: update_pics,
                acceptance_of_marketing_consent: update_consent
            }
        }
    }
    Update_detail(reqData)
    .then(res => {
        if(res.data.err){
            console.log(res.data.err)
            setError(true)
            setError_msg(res.data.err.message)
        }else{
            setSuccess(true)
        }
    })
    .catch(err =>{
        console.log(err)
    })
}

        return (
            <div id="Detail">
                {error ? <Alert variant='danger'> * Error: {error_msg} *</Alert> : null}
                <Container>
                    <Form>
                    <Breadcrumb className="mb-3">
                        <Breadcrumb.Item href="#" >Customer – Customer Profile</Breadcrumb.Item>
                    </Breadcrumb>
                        <Row className="mb-3">
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>System ID </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={systemID}
                                    disabled={true}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Staff / Artiste Number </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={staff_number}
                                    disabled={true}
                                />
                            </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Account Creation Date </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={account_creation_date}
                                    disabled={true}
                                />
                            </InputGroup>
                            </Col>
                        </Row>
                        <Breadcrumb className="mb-3">
                            <Breadcrumb.Item href="#">Personal Information</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Row className="mb-3">
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Last Name </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={last_name}
                                    disabled={true}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text>First Name </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={first_name}
                                    disabled={true}
                                />
                            </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Nickname </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={nickname}
                                    disabled={true}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Gender </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={gender}
                                    disabled={true}
                                />
                            </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Email </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={email}
                                    disabled={true}
                                />
                            </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Login phone code </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={login_phone_code}
                                    disabled={true}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Login Mobile Number </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={login_mobile_number}
                                    disabled={true}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Login mobile verified means </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={login_mobile_verified_means}
                                    disabled={true}
                                />
                            </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Region </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder={region}
                                        disabled={true}
                                    />
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Phone Code </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder={phone_code}
                                        disabled={true}
                                    />
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Mobile Number </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder={mobile_numbers}
                                        disabled={true}
                                    />
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Mobile verified means </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder={mobile_verified_means}
                                        disabled={true}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Year of Birth </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={birth_year}
                                    disabled={true}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Month of Birth </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder={birth_month}
                                    disabled={true}
                                />
                            </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Acceptance of PICS & Acceptance Date  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl as="select"
                                    value={pics}
                                    placeholder={pics}
                                    disabled={!edit}
                                    onChange={ event => {
                                        setPics(event.target.value);
                                    }}
                                >
                                    <option value = {'1'}>Y</option>
                                    <option value = {'0'}>N</option>
                                    <option value = {'Empty'}>Empty</option>
                                </FormControl>

                                <FormControl
                                    placeholder={pics_date}
                                    disabled={true}
                                />
                                {/*<DatePicker 
                                    className="form-control"
                                    disabled={true}
                                    selected={pics_date} 
                                />*/}
                            </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Acceptance of Marketing Consent & Acceptance Date  </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl as="select"
                                    value={consent}
                                    placeholder={consent}
                                    disabled={!edit}
                                    onChange={ event => {
                                        setConset(event.target.value);
                                    }} 
                                >
                                    <option value = {'1'}>Y</option>
                                    <option value = {'0'}>N</option>
                                    <option value = {'Empty'}>Empty</option>
                                </FormControl>
                                <FormControl
                                    placeholder={consent_date}
                                    disabled={true}
                                />
                                {/*<DatePicker 
                                    className="form-control"
                                    disabled={true}
                                    selected={consent_date} 
                                />*/}

                            </InputGroup>
                            </Col>
                        </Row>
                    {success ? <Alert variant='success'> * Update Success *</Alert> : null}
                    <Row className="mb-3">
                        <Col className="float-right" xs ={10}>
                            
                            { error ? null : admin ? edit ? 
                            <Button onClick = {handleSubmit} className="float-right" href="#" >Confirm</Button> : <Button className="float-right" href="#" onClick = {handleClick}>Edit</Button>
                        : null }
                        </Col>
                        <Col className="float-right">
                            <Button className="float-right" href="/" >Back</Button> 
                        </Col>
                    </Row>
                    </Form>
                </Container>
            </div>
        );
    
}
